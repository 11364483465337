<template>
  <Loader
    v-if="loading"
    :geolocating="geolocating"
  />
  <div v-else>
    <section class="section container">
      <Notification
        type="warning"
      >
        Oops! It looks like you scanned a deal from outside of <Brand />!
      </Notification>
      <Notification
        v-if="error"
        type="danger"
      >
        {{ error }}
      </Notification>

      <p class="block">
        Please re-scan <strong>{{ deal.business.name }}</strong>'s <em>{{ deal.description }}</em> QR code from the deal screen.
      </p>
      <template
        v-if="nearestLocation"
      >
        <p class="block">
          You're at the <em>{{ nearestLocation.formattedAddress }}</em> location.
        </p>
        <button
          class="button is-medium is-fullwidth is-info block"
          @click="goNearest"
        >
          Return to Deal
        </button>
      </template>
      <template
        v-else-if="!error"
      >
        <p class="block">
          Which location are you at?
        </p>
        <div class="buttons block">
          <button
            v-for="l in deal.business.locations"
            :key="l.id"
            class="button is-medium is-fullwidth is-info"
            @click="goLocation(l)"
          >
            {{ l.formattedAddress }}
          </button>
        </div>
      </template>
      <template
        v-else
      >
        <button
          class="button is-medium is-fullwidth is-info block"
          @click="go('/')"
        >
          Back to Deals
        </button>
      </template>
    </section>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors"
import distance from "@turf/distance"
import * as turf from "@turf/helpers"
import Brand from "@/components/Brand.vue"
import Loader from "@/components/Loader.vue"
import Notification from "@/components/Notification.vue"

export default {
  props: {
    dealCode: {
      type: String,
      required: true
    }
  },
  components: {
    Brand,
    Loader,
    Notification
  },
  data() {
    return {
      loading: false,
      geolocating: false,
      error: null,
      deal: null,
      nearestLocation: null
    }
  },
  computed: {
    currentDeal: function() {
      return this.$store.getters.currentDeal
    },
    currentLocation: function() {
      return this.$store.getters.currentLocation
    }
  },
  mounted() {
    this.updateData()
  },
  methods: {
    go(path) {
      this.$router.push(path)
    },
    goNearest() {
      this.goLocation(this.nearestLocation)
    },
    goLocation(location) {
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${location.id}`
      this.go(path)
    },
    findNearestDealLocation() {
      return new Promise((resolve, reject) => {
        this.geolocating = true
        this.$store.dispatch("getLocation")
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            this.geolocating = false

            if (this.currentLocation.lat && this.currentLocation.lng) {
              if (this.deal.business.locations.length === 1) {
                resolve(this.deal.business.locations[0])
              } else if (this.deal.business.locations.length > 1) {
                const p1 = turf.point([this.currentLocation.lng, this.currentLocation.lat])
                this.deal.business.locations.sort((a, b) => {
                  const u = {units: "miles"}
                  const pa = turf.point([a.lng, a.lat])
                  const pb = turf.point([b.lng, b.lat])
                  return distance(p1, pa, u) - distance(p1, pb, u)
                })
                resolve(this.deal.business.locations[0])
              } else {
                reject(new Error("Could not determine which location you're at."))
              }
            } else if (this.deal.business.locations.length === 1) {
              resolve(this.deal.business.locations[0])
            } else {
              reject(new Error("Could not determine which location you're at."))
            }
          })
        })
    },
    updateData() {
      this.loading = true
      new Promise((resolve, reject) => {
        this.$store.dispatch("getDealByCode", this.dealCode)
          .then(() => {
            this.deal = this.currentDeal
            if (this.deal) {
              this.findNearestDealLocation()
                .then(resp => {
                  this.nearestLocation = resp
                  resolve()
                })
                .catch(error => {
                  reject(error)
                })
            } else {
              reject(new Error("Deal not found"))
            }
          })
      })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
